@tailwind base;
@tailwind components;
@tailwind utilities;


/** Quincy Font
*
**/
@font-face {
  font-family: 'Quincy_CF';
  src: local('Quincy_CF'), url(./assets/fonts/quincy/quincycf-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quincy_CF';
  src: local('Quincy_CF'), url(./assets/fonts/quincy/quincycf-light.ttf) format('truetype');
  font-weight: 300;
}


/** Proxima Nova Font
*
**/
@font-face {
  font-family: 'Proxima_Nova';
  src: local('Proxima_Nova'), url(./assets/fonts/quincy/proximanova-regular.ttf) format('truetype');
  font-weight: normal;
  font-style:normal
}

@font-face {
  font-family: 'Proxima_Nova';
  src: local('Proxima_Nova'), url(./assets/fonts/quincy/proximanova-medium.ttf) format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Proxima_Nova';
  src: local('Proxima_Nova'), url(./assets/fonts/quincy/proximanova-bold.ttf) format('truetype');
  font-weight: 700;
}

/**
* Satoshi font
*
*/
@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'), url(./assets/fonts/Satoshi-Variable.ttf) format('truetype');
  font-weight: 1 999;
}


/**
* Outfit font
*
*/
@font-face {
  font-family: 'Outfit';
  src: local('Outfit'), url(./assets/fonts/Outfit-VariableFont_wght.ttf) format('truetype');
  font-weight: 1 999;
}


body {
  margin: 0;
  font-family: 'Outfit' ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.RSPBprogression{
  height: 100%;
}

input:focus-visible, button:focus-visible, ul:focus-visible, div:focus-visible{
  outline: 0px;
}

.backdrop-blur-md {
  backdrop-filter: blur(4px); /* Adjust the blur radius as needed */
}


