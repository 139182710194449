.horizontal-slider {
    width: 100%;
    height: 40px;
    margin: auto;
  }
  .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    margin-top:3px;
    /* background: #ffffff; */
    border: 6px solid #6640ff41;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }

  .example-thumb >div{
    border: 6px solid #6640ff93;
    height: 100%;
    width: 100%;
    border-radius: 100%;
  }

  .example-thumb >div>span{
    border: 6px solid #6640ff;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: block;
  }


  .example-thumb.active {
    /* background-color: grey; */
  }
  .example-track {
    position: relative;
    background: #2B2A3514;
  }
  .example-track.example-track-0 {
    background: #6640ff;
  }
  .horizontal-slider .example-track {
    top: 20px;
    height: 5px;
    border-radius: 10px;
  }
  .horizontal-slider .example-thumb {
    top: 2px;
    width: 35px;
    outline: none;
    height: 35px;
    line-height: 38px;
  }

  .example-mark{
height:13px;
width: 2px;
background-color: #6640ff;
top:15.3px;
border-radius: 10px;
  }